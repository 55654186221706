
import { TenorSearch } from "@/services/tenorService";
import { defineComponent, PropType, ref, onMounted, onBeforeUnmount } from "@vue/runtime-core";
import { insert } from "text-field-edit";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<TenorSearch[]>,
      required: true,
    },
    inputElement: {
      type: Object as PropType<HTMLTextAreaElement>,
      required: true,
    },
  },
  data() {
    return {
      isFocused: ref(true),
    };
  },
  methods: {
    onClick(url: string) {
      insert(this.inputElement, url + " ");
      this.inputElement.focus();
      this.$emit("close");
    },
    handleWindowFocus() {
      this.isFocused = true;
    },
    handleWindowBlur() {
      this.isFocused = false;
    },
  },
  mounted() {
    window.addEventListener("focus", this.handleWindowFocus);
    window.addEventListener("blur", this.handleWindowBlur);
  },
  beforeUnmount() {
    window.removeEventListener("focus", this.handleWindowFocus);
    window.removeEventListener("blur", this.handleWindowBlur);
  },
  computed: {
    isWindowFocus() {
      return this.isFocused;
    },
  },
});
