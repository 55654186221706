
import { defineComponent } from "vue";
import {
  tenorCategories,
  TenorCategory,
  TenorSearch,
  tenorSearch,
} from "@/services/tenorService";
import Categories from "./GifCategories.vue";
import GifSearch from "./GifSearch.vue";

export default defineComponent({
  props: ["inputElement"],
  emits: ["click", "close"],
  components: { Categories, GifSearch },
  data() {
    return {
      search: "",
      categories: [] as TenorCategory[],
      searchResults: [] as TenorSearch[],
      searchTimeout: null as null | number,
    };
  },
  methods: {
    async fetchSearch() {
      if (!this.search) return;
      this.searchResults = await tenorSearch(this.search);
    },
    searchImmidiate(value: string) {
      this.search = value;
      this.$nextTick(() => {
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.fetchSearch();
      });
    },
  },
  watch: {
    search() {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = null;
      this.searchTimeout = window.setTimeout(this.fetchSearch, 1000);
    },
  },
  async mounted() {
    this.categories = await tenorCategories();
    if (!this.$isMobile) {
      (this.$refs.search as any).focus();
    }
  },
});
