
import { TenorCategory } from "@/services/tenorService";
import { defineComponent, PropType } from "@vue/runtime-core";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<TenorCategory[]>,
    },
  },
});
